import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Dropdown, Nav, Navbar, NavbarProps, SelectPicker } from "rsuite";
import { icons } from "../../assets";
import "./style.scss";
import {
  getLocalizedMenuStrings,
  getNavbarMenuSelector,
  getUserState,
  languageSelector,
  languagesSelector,
} from "../../redux/selectors";
import {
  AccountTypeParams,
  CommunicationLanguageObject,
  RootState,
} from "../../types";
import { Button } from "..";
import { ButtonType } from "../Button";
import Flag from "react-flagkit";
import {
  getAccountTypes,
  getCommunicationLanguages,
  logoutAction,
  setLanguage,
} from "../../redux/actions";
import { color } from "../../utils/Properties";
import RegistrationFormModal from "./registration-form-modal";
import { generateLanguagesPickerArray } from "../../utils/Helpers";
import { setAuthenticate } from "../../redux/actions/appActions";
import { accountApproved } from "../../utils/userTypes";
import MobileNavBar from "./MobileNavbar";

const NavBarInstance = ({
  appearance,
  style,
  onSelect,
  activeKey,
}: NavbarProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const strings = useSelector(getLocalizedMenuStrings);
  const menuList = useSelector(getNavbarMenuSelector);
  const language = useSelector(languageSelector);
  const languages = useSelector(languagesSelector);
  const [show, setShow] = useState<boolean>(false);
  const [accountType, setAccountType] = useState<AccountTypeParams[]>();
  const [comLang, setComLang] = useState<CommunicationLanguageObject[]>();
  const user = useSelector(getUserState);
  return (
    <>
      <RegistrationFormModal
        show={show}
        onHide={() => setShow(false)}
        types={accountType || []}
        comLang={comLang}
      />
      <Navbar
        className="lg-navbar-menu"
        appearance={appearance}
        style={{ ...style, display: "flex", overflow: "visible" }}
      >
        <Navbar.Header
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            paddingBlock: 8,
            paddingInline: 0,
          }}
        >
          {window.location.hostname.includes("rapid") ? (
            <img src={icons.rapid} alt="" width={200} />
          ) : (
            <img
              src={icons.rapid}
              alt=""
              width={220}
              style={{ marginInlineStart: -22 }}
            />
          )}
        </Navbar.Header>
        <Navbar.Body
          style={{
            width: `calc(100% - 106px)`,
            height: "100%",
            overflow: "visible",
          }}
        >
          <Nav
            className="customNavbar"
            style={{
              overflow: "auto",
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            onSelect={onSelect}
            activeKey={activeKey}
            pullRight
          >
            {menuList &&
              menuList.map((item) => {
                if (item.subItems) {
                  return (
                    <Dropdown
                      key={item.id}
                      title={item.name}
                      className="navbarDropdown"
                      trigger={["click", "hover"]}
                    >
                      {item.subItems.map((subItem) => {
                        return (
                          <Dropdown.Item
                            key={subItem.id}
                            componentClass={Link}
                            to={accountApproved ? subItem.link : "my-profile"}
                          >
                            {subItem.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown>
                  );
                } else {
                  return (
                    <Nav.Item
                      className={
                        item.auth && user
                          ? item.auth?.some((x) => x === user.AccountType.Code)
                            ? ""
                            : "display-none"
                          : ""
                      }
                      componentClass={Link}
                      to={accountApproved ? item.link : "my-profile"}
                      style={{
                        width: "max-content",
                        marginBlockStart: activeKey === item.id ? -3 : 0,
                        backgroundColor:
                          activeKey === item.id ? "#f7f7fa" : "transparent",
                        borderTop:
                          activeKey === item.id ? "3px solid #00A3E0" : "none",
                      }}
                      eventKey={item.id}
                      key={item.id}
                    >
                      <span
                        style={{
                          fontWeight: 500,
                          color: "#215CA0",
                        }}
                        className="regularText"
                      >
                        {item.name}
                      </span>
                    </Nav.Item>
                  );
                }
              })}
            {user ? (
              <>
                <SelectPicker
                  className="navbarSelectPicker"
                  style={{ marginBlockStart: 9 }}
                  data={generateLanguagesPickerArray(languages)}
                  placement="bottomEnd"
                  cleanable={false}
                  value={language === "en" ? "US" : language?.toUpperCase()}
                  onSelect={async (language) => {
                    await dispatch(
                      setLanguage(language === "us" ? "en" : language)
                    );
                    history.go(0);
                  }}
                  renderMenuItem={(label, item) => {
                    return (
                      <>
                        <span
                          className="navbarLanguageDropdownIcon"
                          style={{
                            marginInlineEnd: 10,
                          }}
                        >
                          <Flag country={item.value.toUpperCase()} />
                        </span>
                        <span className="navbarLanguageDropdownItem">
                          {label}
                        </span>
                      </>
                    );
                  }}
                  renderValue={(value, item) => {
                    return <Flag country={value} />;
                  }}
                />
                <Dropdown
                  title={`${user.FirstName} ${user.LastName}`}
                  className="navbarDropdown"
                  trigger={["click", "hover"]}
                >
                  <Dropdown.Item componentClass={Link} to={"/my-profile"}>
                    {strings.getString("myprofile")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    componentClass={Link}
                    to={"/"}
                    onSelect={() => {
                      dispatch(setAuthenticate(false));
                      dispatch(logoutAction());
                    }}
                  >
                    {strings.getString("logout")}
                  </Dropdown.Item>
                </Dropdown>
              </>
            ) : (
              <>
                <li
                  className="rs-nav-item"
                  style={{
                    marginInline: "5px 10px",
                    marginBlockStart: 4,
                    width: 110,
                  }}
                >
                  <Button
                    className="navbarRegisterButton"
                    title={strings.getString("register").toUpperCase()}
                    buttonType={ButtonType.inverted}
                    style={{
                      borderColor: "#37A75B",
                      backgroundColor: "#37A75B",
                      color: color.textAccent,
                    }}
                    onClick={() => {
                      dispatch(
                        getAccountTypes({
                          payload: {
                            onSuccess: (message, payload) => {
                              setAccountType(payload);
                            },
                            onError: (message) => { },
                          },
                          url: "System/GetAccountTypes",
                        })
                      );
                      dispatch(
                        getCommunicationLanguages({
                          payload: {
                            onSuccess: (message, payload) => {
                              setComLang(payload);
                            },
                            onError: (message) => { },
                          },
                          url: "System/GetCommunicationLanguage",
                        })
                      );
                      setShow(true);
                    }}
                  />
                </li>
                <SelectPicker
                  className="navbarSelectPicker"
                  style={{ marginBlockStart: 9 }}
                  data={generateLanguagesPickerArray(languages)}
                  placement="bottomEnd"
                  cleanable={false}
                  value={language === "en" ? "US" : language?.toUpperCase()}
                  onSelect={async (language) => {
                    await dispatch(
                      setLanguage(language === "us" ? "en" : language)
                    );
                    history.go(0);
                  }}
                  renderMenuItem={(label, item) => {
                    return (
                      <>
                        <span
                          style={{
                            marginInlineEnd: 10,
                          }}
                        >
                          <Flag country={item.value.toUpperCase()} />
                        </span>
                        {label}
                      </>
                    );
                  }}
                  renderValue={(value, item) => {
                    return <Flag country={value} />;
                  }}
                />
                <li
                  className="rs-nav-item"
                  style={{
                    height: 22,
                    width: 2,
                    backgroundColor: color.borderColor,
                    marginInlineEnd: 10,
                    marginBlockStart: 16,
                  }}
                />
                <li
                  className="rs-nav-item login navbarloginButton"
                  style={{
                    marginBlockStart: 10,
                    cursor: "pointer",
                    paddingTop: 7,
                  }}
                  onClick={() => {
                    dispatch(setAuthenticate("truck"));
                    history.push("login");
                  }}
                >
                  {strings.getString("login")}{" "}
                </li>
              </>
            )}
          </Nav>
        </Navbar.Body>
      </Navbar>
    </>
  );
};

const NavBar = () => {
  const [activeKey, setActiveKey] = useState("");
  const isLogged = useSelector((state: RootState) => state.user.user?.UserID);

  return (
    <div className="homepage-nav" style={{ borderColor: color.side }}>
      <div
        className={
          isLogged
            ? "mainWidth navbarContentDiv"
            : "homepageWidth  navbarContentDiv"
        }
      >
        <NavBarInstance
          style={{ height: "100%", width: "100%", color: "white !important" }}
          appearance="subtle"
          activeKey={activeKey}
          onSelect={(eventKey: string) => setActiveKey(eventKey)}
        />
        <MobileNavBar activeKey={activeKey} />
      </div>
    </div>
  );
};

export default NavBar;
