import "./assets/scss/homepage.scss";
import "./assets/scss/auth.scss";
import "./assets/scss/profile.scss";
import "./assets/scss/listpage.scss";
import "./assets/scss/component.scss";
import "./assets/scss/register.scss";
import "./App.scss";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import reduxStore from "./redux/index";
import { PersistGate } from "redux-persist/integration/react";
import "rsuite/dist/styles/rsuite-default.css";
import "devextreme/dist/css/dx.light.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import DirectionProvider, {
  DIRECTIONS,
  //@ts-ignore
} from "react-with-direction/dist/DirectionProvider";
import { NavbarListType } from "./types";
import {
  authenticateSelector,
  getLocalizedMenuStrings,
  isLoadingState,
  languageSelector,
  languagesSelector,
} from "./redux/selectors";
import { Loader, Navbar } from "./components";
import RequireMoment from "./utils/RequireMoment";
import {
  getAllSystem,
  getLanguages,
  logoutAction,
  setLanguage,
  setNavbarMenu,
} from "./redux/actions";
import "react-toastify/dist/ReactToastify.css";
import BaseScreen from "./screens/base-screen";
import Authenticate from "./screens/authenticate";
import ChangePassword from "./screens/authenticate/change-password";
import { auctionAuth, loadUserAuth, truckUserAuth } from "./utils/userTypes";
import LocaleProvider from "rsuite/lib/IntlProvider";
import { RsTRLocales } from "./utils/Properties";
import axios from "axios";

const { store, persistor } = reduxStore();

const AuthenticateActiveTwo = (props: any) => (
  <Authenticate changeActivePage={2} {...props} />
);

function Nav() {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingState);
  const strings = useSelector(getLocalizedMenuStrings);
  const isAuthenticate = useSelector(authenticateSelector);
  const language = useSelector(languageSelector);
  const location = useLocation();
  const languages = useSelector(languagesSelector);

  const navbarMenuList: NavbarListType[] = [
    {
      name: strings.getString("home"),
      id: "1",
      link: "/",
    },
    {
      name: strings.getString("have_truck"),
      id: "2",
      link: "/truck-registration",
      auth: loadUserAuth,
    },
    {
      name: strings.getString("search_trucks"),
      id: 3,
      link: "/search-trucks?page=1&take=10",
      auth: truckUserAuth,
    },
    {
      name: strings.getString("have_load"),
      id: 4,
      link: "/load-registration",
      auth: truckUserAuth,
    },
    {
      name: strings.getString("search_loads"),
      id: 5,
      link: "/search-loads?page=1&take=10",
      auth: loadUserAuth,
    },
    /*   {
        name: strings.getString("auctions"),
        id: 6,
        link: "/auctions/open/purpose",
        auth: auctionAuth,
      }, */
    {
      name: strings.getString("contact_us"),
      id: 7,
      link: "/contact-us",
    },
  ];

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.request.status === 401) {
          dispatch(logoutAction());
        }
        return Promise.reject(error);
      }
    );
    dispatch(getAllSystem());
    getLanguageFunc();
    RequireMoment(strings.getInterfaceLanguage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const getLanguageFunc = async () => {
    const array = new URLSearchParams(location.search);
    const lang = array.get("lang");
    if (lang) {
      await dispatch(getLanguages());
      const currentlang = languages.find((x) => x.culture.includes(lang));
      currentlang &&
        (await dispatch(
          setLanguage(currentlang.flag === "us" ? "en" : currentlang.flag)
        ));
    } else {
      return await dispatch(getLanguages());
    }
  };
  useEffect(() => {
    dispatch(setNavbarMenu(navbarMenuList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const getLocale = (): object | undefined => {
    switch (language) {
      case "tr":
        return RsTRLocales;

      default:
        return require("rsuite/lib/IntlProvider/locales/en_US");
    }
  };
  return (
    <LocaleProvider locale={getLocale()}>
      <div className="App">
        <div className="masterPage">
          {location.pathname.includes("/change-password") ||
            location.pathname === "/forgot-password" ||
            location.pathname === "/login" ||
            location.pathname === "/email-confirmed" ? (
            <Switch>
              <Route
                exact
                path="/change-password/:Token"
                component={ChangePassword}
              />
              <Route exact path="/login" component={Authenticate} />
              <Route exact path="/email-confirmed" component={Authenticate} />
              <Route
                exact
                path="/forgot-password"
                component={AuthenticateActiveTwo}
              />
            </Switch>
          ) : isAuthenticate === "client" || isAuthenticate === "truck" ? (
            <Authenticate />
          ) : (
            <>
              <div className="headerBarDiv">
                <Navbar />
              </div>
              <BaseScreen />
            </>
          )}
          {isLoading ? <Loader /> : null}
        </div>
      </div>
    </LocaleProvider>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <DirectionProvider direction={DIRECTIONS.LTR}>
            <Nav />
          </DirectionProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
